const path_parameter = {}

const PathParameterReducer  = (state=path_parameter, action)=>{
  let data = action.data;
  switch (action.type){
    case "ASSIGN_PARAMETER":
      return{
        ...state, data
      };
    case "REMOVE_PARAMETER":
      data  = null;
      return{
        ...state, data
      }
    default:
      return{
        ...state
      }
  }
}

export default PathParameterReducer;
