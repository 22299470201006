let path_parameter = {};

export const changePathParameter = (params) => {
  path_parameter = params;
  return dispatch=>{
    dispatch({
      type: "ASSIGN_PARAMETER",
      data: path_parameter
    })
  }
}

export const removePathParameter = () => {
  path_parameter = {};
  return dispatch=>{
    dispatch({
      type: "REMOVE_PARAMETER",
      data: path_parameter
    })
  }
}
