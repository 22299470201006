const ExamTaskReducer  = (state={}, action)=>{
  switch(action.type){
    case "SET_TASK":
      let data = action.data;
      return{
        ...state, data
      }
    default:
      return{...state}
  }
}
export default ExamTaskReducer;
