import React, {useState} from "react"
import {
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap"
import moment from "moment";
import * as Icon from "react-feather";
import SweetAlert from "react-bootstrap-sweetalert";
import {getAuthData, putAuthData} from "../../../redux/actions/auth/loginActions";
import { history } from "../../../history";
import {getItem, setItem} from "../../../utility/localData"
import {useTranslation, withTranslation} from "react-i18next";
import {removePathParameter} from "../../../redux/actions/path_parameter/index"
import {connect} from "react-redux";
import {FormatContent} from "../../../utility/contentData";
import i18n from "i18next";

const LogoutButtonComponent = (props) => {
  let [showConfirm, setShowConfirm] = useState(false);
  let {t, i18n} = useTranslation();
  return(
    <>
    {history.location.pathname !== "/exam/"||history.location.pathname !== "/exam"?
      <div>
        <div onClick={()=>{props.logoutConfirm()}} className={"bg-primary round d-flex"} style={{ width: 40, height: 40 }}>
          <Icon.Power
            className={"m-auto"}
            style={{ color: 'white' }}
            width={28}
            height={28}
          />
        </div>
      </div>
       :""
    }
    </>
  )
}

const Greetings  = ({t}) => {
  return(
    <div>
      {moment().hours()>=0&&moment().hours()<11?t('exam.greeting.morning.label'):
      moment().hours()>=10&&moment().hours()<14?t('exam.greeting.afternoon.label'):
      moment().hours()>=14&&moment().hours()<19?t('exam.greeting.evening.label'):
      t('exam.greeting.night.label')}
    </div>
  )
}

class NavbarUser extends React.PureComponent {

  constructor(props) {
    super(props);
    //component state
    this.state = {
      user: "",
      showLogoutConfirm: false
    }
    this.handleLogoutConfirm = this.handleLogoutConfirm.bind(this);
  }

  componentDidMount(){
    window.addEventListener('beforeunload', (e) => {
      if(history.location.pathname !== '/login')
        setItem('force_logout', true);
    });

    if(getAuthData()){
      //check session expire first
      if(getItem("session_expire")&&Number.parseInt(getItem("session_expire") < moment.unix())){
        //logout
        localStorage.clear();
        history.push(process.env.PUBLIC_URL+"/login");
      }else{
        //then restore user data
        const {t} = this.props;
        let {user} = this.state;
        user = getAuthData();
        this.setState({user});
      }
    }else{
      history.push(process.env.PUBLIC_URL+"/login");
    }

  }

  handleLogoutConfirm(){
    let {showLogoutConfirm} = this.state;
    showLogoutConfirm = true;
    this.setState({showLogoutConfirm});
  }

  render() {
    //
    const {t, removePathParameter} = this.props;
    let {user, showLogoutConfirm} = this.state;
    //
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                <Greetings t={t}/>
              </span>
              <span className="user-status">{user.fullname}</span>
            </div>
            <span data-tour="user">
              <LogoutButtonComponent logoutConfirm={this.handleLogoutConfirm}/>
            </span>
          </DropdownToggle>
        </UncontrolledDropdown>
        {/*Log Out*/}
        <SweetAlert
        key={1}
          info
          title=""
          show={showLogoutConfirm}
          onConfirm={()=>{
            removePathParameter();
            localStorage.clear();
            window.clearInterval()
            window.location.replace(process.env.PUBLIC_URL+"/login")
            let {showLogoutConfirm} = this.state;
            showLogoutConfirm = true;
            this.setState({showLogoutConfirm});
          }}
          cancelBtnStyle={{border: "1px solid #00587a"}}
          onCancel={()=>{
            let {showLogoutConfirm} = this.state;
            showLogoutConfirm = false;
            this.setState({showLogoutConfirm});
          }}
          showCancel={true}
          showConfirm={true}
          cancelBtnCssClass={"text-small"}
          confirmBtnCssClass={"text-small"}
          confirmBtnText={FormatContent(t('exam.logout.ok.button'))}
          cancelBtnText={FormatContent(t('exam.logout.cancel.button'))}
        >
          <div style={{fontSize: "18px"}}>{FormatContent(t('exam.logout.title'))}</div>
        </SweetAlert>

      </ul>
    )
  }
}
export default connect(null, {removePathParameter})(withTranslation(['translation', 'id_trans', 'en_trans'])(NavbarUser));
