export const loadState = (state) => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState != null) {
        let stateJson = JSON.parse(serializedState);
        state = stateJson;
      }
      return state;  
    } catch (err) {
      return state;
    }
  };
  
  export const saveState = (state) => {
    try{
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    }catch{
  
    }
  }