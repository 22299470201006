import React from "react";
import {getAuthData} from "../redux/actions/auth/loginActions";
import {getItem, setItem} from "./localData";

export const FormatContent = (content, replacements) => {
  if(content){
    return(content.split("\n").map((item, i)=>{
      let itemToShow = item;
      let itemDom = <></>;
      if(item.includes("$bold{")){
        itemToShow = "";
        let itemDomSplit  = item.split("$bold{");
        itemDom = <div style={{display: "inline"}} key={i}>
          {itemDomSplit[0]}
          <div className={"font-weight-bold"}>
            {itemDomSplit[1].split("}")[0]}
          </div>
          {itemDomSplit[1].split("}")[1]}
        </div>
      }
      if(item.includes("$bold_inline{")){
        itemToShow = "";
        let itemDomSplit  = item.split("$bold_inline{");
        itemDom = <div style={{display: "inline"}} key={i}>
          {itemDomSplit[0]}
          <div style={{display: "inline"}} className={"font-weight-bold"}>
            {itemDomSplit[1].split("}")[0]}
          </div>
          {itemDomSplit[1].split("}")[1]}
        </div>
      }
      if(item.includes("$empty")){
        itemToShow = "";
        itemDom = <br/>
      }
      if(item.includes("$italic{")){
        itemToShow = "";
        let itemDomSplit  = item.split("$italic{");
        itemDom = <div key={i}>
          {itemDomSplit[0]}
          <i className={"font-weight-light"} style={{display: "inline"}}>
            {itemDomSplit[1].split("}")[0]}
          </i>
          {itemDomSplit[1].split("}")[1]}
        </div>
      }
  
      if(item.includes("$italic-thin{")){
        itemToShow = "";
        let itemDomSplit  = item.split("$italic-thin{");
        itemDom = <div key={i}>
          {itemDomSplit[0]}
          <i className={"font-weight-light"} style={{display: "inline"}}>
            {itemDomSplit[1].split("}")[0]}
          </i>
          {itemDomSplit[1].split("}")[1]}
        </div>
      }
      if(replacements){
        if(item.includes("${")){
          replacements.forEach(item2=>{
            if(item.includes(item2.key)){
              itemToShow = "";
              itemDom = item2.value;
            }
          })
        }
        if(item.includes("$inline-key{")){
          replacements.forEach(item2=>{
            if(item.includes(item2.key)){
              itemToShow = "";
              let itemDomSplit  = item.split("$inline-key{");
              itemDom = <div key={i}>
                {itemDomSplit[0]}
                <div style={{display: "inline"}}>
                  {item2.value}
                </div>
                {itemDomSplit[1].split("}")[1]}
              </div>
            }
          })
        }
      }
      return (itemToShow?<div key={i}>{itemToShow}</div>:itemDom)
  
    }))
  }
}

export const handleFullscreen = () => {
  let docElm = document.documentElement;
  if (docElm.requestFullscreen) {
    docElm.requestFullscreen();
  } else if (docElm.msRequestFullscreen) {
    docElm.msRequestFullscreen();
  } else if (docElm.mozRequestFullScreen) {
    docElm.mozRequestFullScreen();
  } else if (docElm.webkitRequestFullScreen) {
    docElm.webkitRequestFullScreen();
  }
}

export const FormatContentWithHTML = (content, replacements) => {
  let htmlContent = "";
  content.split("\n").forEach((item, i)=>{
    if(item.includes("$italic{")){
      let itemDomSplit  = item.split("$italic{");
      htmlContent += `<div>
        ${itemDomSplit[0]}
        <i class="font-weight-light" style="display: inline">
          ${itemDomSplit[1].split("}")[0]}
        </i>
        ${itemDomSplit[1].split("}")[1]}
      </div>`
    }else{
      htmlContent += item;
    }
    if(i < content.split("\n").length-1) htmlContent += "<br/>";
  })
  return htmlContent;
}


export const CheckLang = () => {
  let user = getAuthData();
  return user&&user.examLang&&user.examLang.includes("indo")?"id":
    user&&user.examLang&&user.examLang.includes("ing")?"en":"bil";
}
export const CheckForcedLogOut = () => {
  if(getItem('force_logout')){
    //localStorage.clear();
   // window.location.href = "/login";
  }
}
