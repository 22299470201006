import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import pathParameter from "./path_parameter"
import examTask from "./exam_task"
import {loadState} from "../../utility/reduxData"

const appReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  pathParameter: pathParameter,
  exam: examTask,
  examTask: examTask
})

const rootReducer = (state, action) => {
  const persistedState = loadState(state);
  return appReducer(persistedState, action);
}

export default rootReducer
