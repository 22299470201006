export const login = (state = { authUser: {} }, action) => {
  switch (action.type) {
    case "SET_AUTH_DATA":
      return{
        ...state,
        authUser: action.data
      }
    case "PUT_AUTH_DATA":
      state.authUser[action.data.key] = action.data.value;
      return{
        authUser: state.authUser,
        ...state
      }
    default: {
      return state
    }
  }
}
