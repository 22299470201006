import { getItem, setItem } from "../../../utility/localData";

export const setAuthData = authData => {
  setItem("user", JSON.stringify(authData));
}

export const putAuthData = _authData => {
  let user = JSON.parse(getItem("user"));
  user[_authData.key] = _authData.value;
  setAuthData(user);
}

export const getAuthData = () => {
  return JSON.parse(getItem("user"));
}
